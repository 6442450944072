import React, { useEffect, useState } from 'react';
import { ReactComponent as LoaderIcon } from 'images/x-sell-loader.svg';
import { ReactComponent as BankLoading } from 'images/bank-loader.svg';
import StateContainer from 'components/StateContainer';
import { useDispatch, useSelector } from 'react-redux';
import { getLoanOffer } from 'selectors/getLoanOfferData';
import { getApplicationData } from 'selectors/getApplicationData';
import { getApplicationApr, getApplicationData as getApplicationDataThunk, getLoanOfferXSell } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { LoanOfferStep } from 'api/LoanOfferApi';
import { getCheckupProgress, getProgressText } from 'components/FinancialCheckup/Analyzing/Analyzing';
import { FlowComponentType } from 'routes/FlowRouter';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { LoaderResult } from 'enums/FlowNextResults';
import { CurrentFlow } from 'enums/CurrentFlow';
import { setOfferProgress } from 'handlers/loanOffer';
import { getCardData } from 'selectors/getCardData';
import { Dispatch } from 'redux';
        
import { NO_OFFER_STATUSES } from 'constants/offer';

import styles from './Loader.module.scss';

export const crossSell = (dispatch: Dispatch<any>, applicationId: string) => {
  dispatch(
    getLoanOfferXSell({
      applicationId,
      flow: CurrentFlow.FinancialCheckup,
      updateCallback: (data) => {
        dispatch(setOfferProgress(data));
      },
      resumeLink: `${window.location.href}`,
    }),
  );
};

const Loader = ({ handleNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(0);
  const [accountSync, setAccountSync] = useState(false);
  const [totalAccounts, setTotalAccounts] = useState(0);

  const offer = useSelector(getLoanOffer);
  const { applicationId } = useSelector(getCardData);
  const { application, isLoading: isLoadingApplicaiton } = useSelector(getApplicationData);

  useEffect(() => {
    const run = async () => {
      if (isLoadingApplicaiton) {
        return;
      }
      if (offer.isCompleted) {
        if (
          offer.response.data.application_status &&
          NO_OFFER_STATUSES.includes(offer.response.data.application_status)
        ) {
          // Offer is not available, so we want to show an error, but don't load the application
          handleNext(LoaderResult.NoOffer);
        } else if (offer.response.data.application_id && application === undefined) {
          // Offer available, and application hasn't been loaded yet, lets load it
          const app = await dispatchWithUnwrap(getApplicationDataThunk(offer.response.data.application_id!));

          if (offer.response.data.application_status === ApplicationStatusName.OfferAvailable) {
            // getting the apr from the LMS takes up to 3 seconds, so we pre-fetch it after the loan offer is generated
            dispatch(getApplicationApr(offer.response.data.application_id!));
          }

          // Application should have hard offer, but just in case, we check
          if (app.application.hardOffer) {
            handleNext(LoaderResult.Success);
          } else {
            analytics.track('Loan Offer Failed - No hard offer', offer);
            handleNext(LoaderResult.CantGenerateOffer);
          }
        } else {
          crossSell(dispatch, applicationId!);
        }
      } else if (offer.isLoading && offer.progress) {
        // Offer is loading, so we want to show the progress
        setProgress(getCheckupProgress(offer.progress));
        if (offer.progress.step === LoanOfferStep.GettingTradelines && offer.progress.totalAccounts) {
          setAccountSync(true);
          setTotalAccounts(offer.progress.totalAccounts || 0);
        }
      }
    };
    run();
  }, [offer]);

  return (
    <div className={styles.container}>
      <StateContainer
        icon={accountSync ? <BankLoading /> : <LoaderIcon />}
        progress={progress}
        title={getProgressText(offer.progress!, accountSync, totalAccounts)}
      />
    </div>
  );
};

export default Loader;
